<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">CHEQUE BOOK PADS FOR ACCOUNTING SUPPLY</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-layout col>
          <v-flex xs12 md3>
            <v-form ref="form2" class="multi-col-validation">
              <v-select
                v-model="category_id"
                class="mx-2  mt-2"
                dense
                outlined
                label="Category"
                :items="category_items"
                item-value="id"
                item-text="category"
                @change="selected_category"
                :rules="rules.combobox_rule"
              ></v-select>
              <v-select
                v-model="bank_id"
                class="mx-2"
                dense
                outlined
                label="Bank"
                :items="bank_items"
                item-value="id"
                item-text="bank_code"
                @change="selected_bank"
                :rules="rules.combobox_rule"
              ></v-select>
              <v-select
                v-model="request_data_id"
                class="mx-2"
                dense
                outlined
                label="Request Data ID"
                :items="request_data_items"
                item-value="id"
                item-text="id"
                :rules="rules.combobox_rule"
                @change="selected_request_data"
              ></v-select>
              <v-row>
                <v-col cols="6">
                  <v-text-field label="From" readonly v-model="request_from"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="To" readonly v-model="request_to"></v-text-field>
                </v-col>
              </v-row>
              Cheque Book Pad Series
              <v-row>
                <v-col cols="6">
                  <v-text-field label="From" v-model="from_cheque_no"
                                @change="calculation_cheque_series"
                                @keyup="calculation_cheque_series"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="To" readonly v-model="to_cheque_no"></v-text-field>
                </v-col>
              </v-row>
              <v-col cols="12" v-show="alert">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"
                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{alert_message}}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
              <v-col cols="12" md="12" v-if="request_data_id!=''">
                <v-btn
                  class="w-full"
                  color="primary"
                  @click="save_request"
                  v-if="!saving_data"
                >
                  Save Request
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="primary"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-col>
            </v-form>
            <v-data-table dense
                          :headers="headers2"
                          :items="vouchered_items"
            >
              <template v-slot:item="{ item }">
                <tr @click="activerow(item)"
                    :class="{'info white--text': item.id===selectedDepositId}">
                  <td class="black--text">
                    {{ item.month_of }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
          <v-flex xs12 md9 class="mx-2" v-if="data_items.length>0">
            <v-data-table dense
                          :headers="headers"
                          :items="data_items"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.bank.bank_code }}
                  </td>
                  <td>
                    {{ item.from_cheque_no+' - '+item.to_cheque_no }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAccountOutline, mdiAccountCashOutline, mdiInformationOutline} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import category from "@/store/module/data/category";

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      alert_message: '',

      headers: [
        {text: 'Bank Code', value: 'date_of_deposit', sortable: false},
        {text: 'Cheque Book Series Pads', value: 'tin', sortable: false},
      ],
      headers2: [
        {text: 'Month Of', value: 'date_of_deposit', sortable: false},
      ],
      data_items: [],
      vouchered_items: [],
      request_data_items: [],
      bank_items: [],
      category_items: [],
      to_cheque_no: 0,
      from_cheque_no: 0,
      request_to: 0,
      request_from: 0,
      request_data_id: '',
      bank_id: '',
      category_id: '',
      selectedDepositId: -1,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id', 'month_of']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('cheque_book_series_pads', ['initialize_for_accounting_supply','save_cheque_book_pads_to_treasury']),
      initialize_data() {
        this.initialize_for_accounting_supply()
          .then(response => {
            this.bank_items = []
            this.request_data_items = []
            this.category_items = response.data[0].category
            this.vouchered_items=response.data[0].transacion_month
          })
          .catch(error => {
            console.log(error)
          })
      },
      activerow(value) {
        this.data_items = value.cheque_pads
        this.selectedDepositId = value.id;
      },
      calculation_cheque_series() {
        var booklet_counter = parseInt(this.request_to) - parseInt(this.request_from)
        this.to_cheque_no = (parseInt(this.from_cheque_no) + 49) + (booklet_counter * 50)
      },
      selected_request_data(value) {
        this.from_cheque_no='0'
        this.to_cheque_no='0'
        var index = this.request_data_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          var splitedDescription = this.request_data_items[index].description.split(' # Desc.-')[1].split('from ')[1].split(' to ')
          this.request_from = splitedDescription[0]
          this.request_to = splitedDescription[1]
          this.calculation_cheque_series()
        }
      },
      selected_bank(value) {
        this.from_cheque_no='0'
        this.to_cheque_no='0'
        this.request_from='0'
        this.request_to='0'
        var index = this.bank_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.request_data_id = ''
          this.request_data_items = this.bank_items[index].request_data_cheque_book
        }
      },
      selected_category(value) {
        var index = this.category_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.request_data_items = []
          this.bank_id = ''
          this.request_data_id = ''
          this.from_cheque_no='0'
          this.to_cheque_no='0'
          this.request_from='0'
          this.request_to='0'
          this.bank_items = this.category_items[index].bank_depository
        }
      },
      save_request() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form2.validate()) {
          const data = new FormData()
          data.append('request_data_id', this.request_data_id);
          data.append('bank_id', this.bank_id);
          data.append('request_from', this.request_from);
          data.append('request_to', this.request_to);
          data.append('from_cheque_no', this.from_cheque_no);
          this.save_cheque_book_pads_to_treasury(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                var color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              Object.assign(this.$data, initialState())
              this.initialize_data()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
    }
  }
</script>
